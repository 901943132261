//
// Login 5
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import "../../init";

.login.login-4 {
    // Container
    .login-container {
        .login-content {
            width: 450px;

            // Wizard Nav
            .wizard-nav {
                padding: 0;

                // Steps
                .wizard-steps {
                    display: flex;
                    align-items: center;

                    // Step
                    .wizard-step {
                        padding: 0.75rem 0;
                        transition: $transition-link;
                        margin-bottom: 1.5rem;

                        .wizard-wrapper {
                            display: flex;
                            align-items: center;
                        }

                        .wizard-icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: $transition-link;
                            width: 46px;
                            height: 46px;
                            border-radius: 12px;
                            background-color: $gray-100;
                            margin-right: 1rem;

                            .wizard-check {
                                display: none;
                                font-size: 1.4rem;
                            }

                            .wizard-number {
                                font-weight: 600;
                                color: $dark-75;
                                font-size: 1.35rem;
                            }
                        }

                        .wizard-label {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .wizard-title {
                                color: $dark;
                                font-weight: 600;
                                font-size: 1.24rem;
                            }

                            .wizard-desc {
                                color: $text-muted;
                                font-size: $font-size-sm;
                            }
                        }

                        // Done States
                        &[data-wizard-state="done"] {
                            .wizard-icon {
                                transition: $transition-link;
                                background-color: $success-light;

                                .wizard-check {
                                    color: $success;
                                    display: inline-block;
                                }

                                .wizard-number {
                                    display: none;
                                }
                            }

                            .wizard-label {
                                .wizard-title {
                                    color: $text-muted;
                                }

                                .wizard-desc {
                                    color: $gray-400;
                                }
                            }
                        }

                        // Current States
                        &[data-wizard-state="current"] {
                            transition: $transition-link;

                            .wizard-icon {
                                transition: $transition-link;
                                background-color: $success-light;

                                .wizard-check {
                                    color: $success;
                                    display: none;
                                }

                                .wizard-number {
                                    color: $success;
                                }
                            }

                            .wizard-label {
                                .wizard-title {
                                    color: $dark;
                                }

                                .wizard-desc {
                                    color: $gray-500;
                                }
                            }
                        }
                    }
                }
            }

            &.login-content-signup {
                width: 700px;
            }
        }
    }

    // Aside
    .login-aside {
        background: linear-gradient(147.04deg, #CA7B70 0.74%, #5A2119 99.61%);
         width: 700px;

         .login-conteiner {
             height: 100%;
         }
    }
}

// Desktop view
@include media-breakpoint-up(lg) {
    .login.login-4 {
        // Aside
        .login-aside {
            .login-conteiner {
                min-height: 610px !important;
                background-size: 580px;
            }
        }
    }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
    .login.login-4 {
        // Aside
        .login-aside {
            .login-conteiner {
                min-height: 420px !important;
                background-size: 400px;
            }
        }
    }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
    .login.login-4 {
        // Container
        .login-container {
            // Wizard Nav
    	    .wizard-nav {
        		padding: 0;
                align-content: center;

        		// Steps
        		.wizard-steps {
    			    // Step
    			    .wizard-step {
                        margin-bottom: .5rem;
                    }
                }
            }
        }

        // Aside
        .login-aside {
            width: 100% !important;

            .login-conteiner {
                min-height: 210px !important;
                background-size: 200px;
            }
        }
    }
}

// Mobile mode
@include media-breakpoint-down(xs) {
    .login.login-4 {
        // Container
        .login-container {
            width: 100%;
            // Wizard
            .wizard-nav {
               // Steps
               .wizard-steps {
                   display: flex;
                   flex-direction: column;

                   .wizard-step {
                        width: 100%;

                        .wizard-wrapper {
                            .svg-icon {
                                display:none;
                            }
                        }
                    }
                }
            }

            .login-content{
                width: 100%;

                &.login-content-signup {
                    width: 100%;
                }
            }
        }

        // Aside
        .login-aside {
            width: 100% !important;
        }
    }
}

@media (max-width: 1800px) {
    .login.login-4 {
        // Aside
        .login-aside {
             width: 600px;
        }
    }
}
