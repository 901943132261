// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';
@import '~react-perfect-scrollbar/dist/css/styles.css';
// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";
// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }
// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";
// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
.example-highlight>pre {
    background: none transparent !important;
    margin: 0 !important;
    padding-top: 0.5rem;
    code {
        overflow-y: auto;
        display: block;
        span {
            background: none transparent !important;
        }
    }
}

.json>pre {
    background: none #fff !important;
}

.example-code>.json {
    background: none #fff !important;
}

.symbol.symbol-45>svg {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.react-bootstrap-table {
    overflow-x: auto;
}

.react-bootstrap-table {
    th {
        outline: none;
        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }
            &:hover {
                cursor: pointer;
                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }
    .table.table-head-custom thead tr {
        .sortable-active {
            color: $primary !important;
        }
    }
}

.cursor-default {
    cursor: default !important;
}

.display-block {
    display: block;
}

.z-10 {
    z-index: 10;
}

.alertSnackbar {
    font-size: 14px;
    letter-spacing: 0.6px;
}

.nowrap {
    white-space: nowrap;
}

.wrap {
    white-space: inherit;
}

.devider {
    width: 100%;
    border-bottom: 1px solid #ebedf3;
}

.hoverBlue:hover {
    color: rgb(105, 147, 255);
}

.react-bootstrap-table .row-expand-slide-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
    max-height: 1000px;
    transition: none !important;
}

.TONestedTable_header {
    background-color: #e8eceb;
}

.TONestedTable_bodrer {
    border: 2px solid #e8eceb;
}

.TONestedTable_col-hot {
    background-color: #ff3a0717;
}

.TONestedTable_col-cold {
    background-color: #0b80f524;
}

.w-90 {
    width: 90%;
}

.mw-90 {
    max-width: 90%;
}

.no-select {
    user-select: none;
}

.no-wrap {
    white-space: nowrap;
}

#map {
    min-width: 200px;
    height: 400px;
    width: 100%;
    position: relative;
}

.options-card {
    background-color: #f4f4f4;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    line-height: 133%;
    padding: 10px;
    position: relative;
}

.options-input:not(.w-50):not(.options-input_w-50) {
    width: 100%;
}

.options-input {
    align-items: flex-start;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    padding: 6px 11px 5px;
    position: relative;
    transition: border-color .3s ease;
}

.options-input__container {
    height: 100%;
}

.options-input__container,
.options-input__group {
    flex-grow: 1;
}

.options-input__label {
    display: block;
    font-size: 12px;
    line-height: 133%;
    margin-bottom: 2px;
}

.options-input__radios {
    list-style: none;
    margin: -2px 0 0 -2px;
    padding: 6px 3px 1px 0;
}

.options-input_required:before {
    color: #b6000f;
    content: "*";
    font-size: 16px;
    line-height: 16px;
    position: absolute;
    right: 12px;
    top: 6px;
}

.options-input_required .options-input__label {
    padding-right: 12px;
}

.options-input__wrapper:not(.w-50) {
    flex-grow: 1;
}

.options-input__wrapper input.form-control {
    height: 26px;
}

.options-input__wrapper input.form-control,
.options-input__wrapper textarea.form-control {
    border: none;
    box-shadow: none;
    font-size: 14px;
    line-height: 143%;
    max-width: 100%;
    padding: 0;
    text-overflow: ellipsis;
    width: 100%;
}

.options-input__units {
    margin-left: auto;
    max-width: 32%;
}

.options-input__select_unit {
    color: #bfbebe;
    text-align: right;
}

.options-input__select {
    background-position: calc(100% - 5px) 50%;
    border: none;
    box-shadow: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 143%;
    padding: 4px 20px 5px 0;
    text-overflow: ellipsis;
    transition: color .3s ease;
}