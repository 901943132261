.svg-icon-key {
    svg {
        g [fill] {
            fill: #fcbf29 !important;
        }
    }
}

.card.card-endpoint > .card-body {
  padding: 1rem 1.25rem !important;
}
.card.card-endpoint  {
  background-color: #d3d3d3!important;
}

.text-endpoint  {
  color: #838383!important;
}

.btn.btn-outline.btn-outline-dashed {
    border-width: 1px;
    border-style: dashed;
}

.btn-check:checked+.btn.btn-active-light-primary, .btn.btn-active-light-primary:hover:not(.btn-active)  {
    color: #1b84ff;
    border-color: #e9f3ff;
    background-color: #e9f3ff !important;
}

.btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}

.btn-check:checked+.btn.btn-outline.btn-outline-dashed, .btn.btn-active-light-primary:hover:not(.btn-active){
    border-color: #1b84ff;
}

.fw-semibold {
    font-weight: 500!important;
}
.text-gray-900 {
    color: #071437!important;
}

.btn>i {
    display: inline-flex;
    font-size: 1rem;
    padding-right: 0.35rem;
    vertical-align: middle;
}
.text-start {
    text-align: left!important;
}
.fv-plugins-icon-container {
    position: relative;
}

.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg):not(.shadow-xs) {
    box-shadow: none;
}
.btn.btn-outline-dashed {
    border: 1px dashed var(--bs-gray-300);
}

 .registration .wizard-nav {
    padding: 0;
  }
 .registration .wizard-nav .wizard-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .registration .wizard-nav .wizard-steps .wizard-step {
    padding: 0.75rem 0;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    margin-bottom: 1.5rem;
  }
 .registration .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
 .registration .wizard-nav .wizard-steps .wizard-step .wizard-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    width: 46px;
    height: 46px;
    border-radius: 12px;
    background-color: #F3F6F9;
    margin-right: 1rem;
  }
 .registration .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-check {
    display: none;
    font-size: 1.4rem;
  }
 .registration .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-number {
    font-weight: 600;
    color: #3F4254;
    font-size: 1.35rem;
  }
 .registration .wizard-nav .wizard-steps .wizard-step .wizard-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
 .registration .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
    color: #181C32;
    font-weight: 600;
    font-size: 1.24rem;
  }
 .registration .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-desc {
    color: #B5B5C3;
    font-size: 0.925rem;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    background-color: #C9F7F5;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon .wizard-check {
    color: #1BC5BD;
    display: inline-block;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon .wizard-number {
    display: none;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-title {
    color: #B5B5C3;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-desc {
    color: #D1D3E0;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    background-color: #C9F7F5;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .wizard-check {
    color: #1BC5BD;
    display: none;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .wizard-number {
    color: #1BC5BD;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-title {
    color: #181C32;
  }
 .registration .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-desc {
    color: #B5B5C3;
  }
 .registration {
    max-width: 700px!important;
    width: 700px !important;
  }
  .login.login-4 .login-aside {
    background: linear-gradient(147.04deg, #CA7B70 0.74%, #5A2119 99.61%);
    width: 700px;
  }
  .login.login-4 .login-aside .login-conteiner {
    height: 100%;
  }
  
  @media (min-width: 992px) {
    .login.login-4 .login-aside .login-conteiner {
      min-height: 610px !important;
      background-size: 580px;
    }
  }
  @media (min-width: 992px) and (max-width: 1399.98px) {
    .login.login-4 .login-aside .login-conteiner {
      min-height: 420px !important;
      background-size: 400px;
    }
  }
  @media (max-width: 991.98px) {
    .login.login-4 .login-container .wizard-nav {
      padding: 0;
      -ms-flex-line-pack: center;
      align-content: center;
    }
    .login.login-4 .login-container .wizard-nav .wizard-steps .wizard-step {
      margin-bottom: 0.5rem;
    }
    .login.login-4 .login-aside {
      width: 100% !important;
    }
    .login.login-4 .login-aside .login-conteiner {
      min-height: 210px !important;
      background-size: 200px;
    }
  }
  @media (max-width: 575.98px) {
    .login.login-4 .login-container {
      width: 100%;
    }
    .login.login-4 .login-container .wizard-nav .wizard-steps {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .login.login-4 .login-container .wizard-nav .wizard-steps .wizard-step {
      width: 100%;
    }
    .login.login-4 .login-container .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .svg-icon {
      display: none;
    }
   .registration {
      width: 100%;
    }
   .registration.login-content-signup {
      width: 100%;
    }
    .login.login-4 .login-aside {
      width: 100% !important;
    }
  }
  @media (max-width: 1800px) {
    .login.login-4 .login-aside {
      width: 600px;
    }
  }

  .registration .wizard-nav .wizard-steps .wizard-step .wizard-arrow.last {
    display: none;
}