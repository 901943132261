.cardSpinner {
  background-color: #fdfdfd94;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fdfdfd94;
  z-index: 2;
}

.cardSpinner-spinner {
  width: 70px;
  height: 70px;
}
